import React, { useState, FC, Fragment, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CardMedia, Fab, Fade, Toolbar, Button, ListItemText, ListItemButton,
  ListItem, List, useScrollTrigger, Box, Divider, Drawer, AppBar
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { RoutesVars } from '../../../const/constRoutes';
import { colorsTheme } from '../../../themes/theme';
import logo from '../../../assets/images/logo.png';

const drawerWidth = '100%';

interface Props { window?: () => Window; }

interface PropsScroll {
  window?: () => Window;
  children: ReactElement;
}

function ScrollTop(props: PropsScroll) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: 'center',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const pages = [
  { name: 'Strona Główna', route: RoutesVars.HOME },
  { name: 'Galeria', route: RoutesVars.GALERY },
  { name: 'Kontakt', route: RoutesVars.CONTACT },
  { name: 'Zaloguj', route: RoutesVars.LOGIN },
];

const AppBarGuest: FC<Props> = ({ window }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => { setMobileOpen((prevState) => !prevState); };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box display="flex" justifyContent="center" alignItems="center"
        sx={{
          bgcolor: colorsTheme.primary.main,
          borderBottom: '1px solid black',
          px: 1,
          py: 1.5,
        }}>
        <CardMedia
          component="img"
          image={logo}
          sx={{ height: 38, width: 233 }}
        />
      </Box>

      <Divider />

      <List>
        {pages.map((item, index) => (<Fragment key={`1-${item.name}`}>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText
                primary={item.name}
                onClick={() => navigate(item.route)}
                sx={{ fontWeight: 700 }}
              />
            </ListItemButton>
          </ListItem>
          {index !== (pages.length - 1) && <Divider variant="middle" key={item.name} />}
        </Fragment>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 10
  });

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav"
        sx={{
          bgcolor: (trigger || location.pathname !== RoutesVars.HOME) ? colorsTheme.primary.main : 'transparent',
          boxShadow: 'none',
          transition: 'background-color 150ms linear'
        }}>
        <Box maxWidth="xl" sx={{ bgcolor: 'transparent', margin: '0 auto', width: '100%' }}>
          <Toolbar>

            {/* Mobile xs -> sm */}
            <Box display="flex" justifyContent="space-between" alignItems="center"
              sx={{
                width: '100%',
                display: { xs: 'flex', md: 'none' },
                px: 1,
                py: 1.5
              }}
            >
              <CardMedia
                component="img"
                image={logo}
                sx={{ height: 38, width: 233 }}
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            {/* Desktop md -> xl */}
            <Box display="flex" justifyContent="space-between" alignItems="center"
              sx={{
                width: '100%',
                display: { xs: 'none', md: 'flex' },
                px: 1,
                py: 1.5
              }}
            >
              <CardMedia
                component="img"
                image={logo}
                sx={{ height: 38, width: 233 }}
              />

              <Box sx={{}}>
                {pages.map((item, index) => (
                  <Button key={item.name}
                    onClick={() => navigate(item.route)}
                    sx={{
                      bgcolor: index !== (pages.length - 1) ? 'transparent' : colorsTheme.primary.dark,
                      fontSize: 20,
                      height: 43,
                      px: 2,
                      color: '#fff',
                      '&:hover': {
                        background: index !== (pages.length - 1) ? 'transparent' : '#c0bbbb',
                        color: index !== (pages.length - 1) ? '#fff' : 'black',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            </Box>

          </Toolbar>
        </Box>
      </AppBar>

      <div id="back-to-top-anchor" />

      <ScrollTop>
        <Fab size="medium" aria-label="scroll back to top"
          sx={{ color: 'white', bgcolor: colorsTheme.primary.dark }}
        >
          <ArrowUpwardIcon />
        </Fab>
      </ScrollTop>

      <nav>
        <Drawer
          anchor='top'
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true, }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default AppBarGuest;
