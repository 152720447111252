import React, { FC, useContext, useState } from 'react';
import {
  DataGrid, GridColDef, GridRenderCellParams, GridToolbarColumnsButton,
  GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridValidRowModel, plPL
} from '@mui/x-data-grid';
import { Backdrop, Box, Button, Fade, Modal, Tooltip } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-hot-toast';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useNavigate } from 'react-router-dom';

import { Vehicle, VehicleEventCreate, VehicleEvent } from '../../../interfaces';
import { createRowData } from './utils/utils';
import TableEmptyState from '../../../components/TableEmptyState';
import AddVehicleEventForm from './Forms/AddVehicleEventForm';
import { modalStyle } from './NoNotifications';
import AlertDialog from './AlertDialog';
import EditVehicleEventForm from './Forms/EditVehicleEventForm';
import { addVehicleEvent, deleteVehicleEvent, updateVehicleEvent } from '../../../api/services/VehicleEventService';
import EventStatusTag from '../../../components/shared/Tags/EventStatusTag';
import EventTypeTag from '../../../components/shared/Tags/EventTypeTag';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { EventsStatusKeys } from '../../../const/constNotifications';
import { RoutesVars } from '../../../const/constRoutes';
import { getSelectedRowsToExport } from '../../../utils/tables/utils';

type Props = {
  events: VehicleEvent[];
  isLoading: boolean;
  vehicle: Vehicle,
};

const VehicleEventTable: FC<Props> = ({ events, isLoading, vehicle }) => {
  const { state: { user } } = useContext(UserContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [eventIdToAction, setEventIdToAction] = useState('');
  const [eventToEdit, setEventToEdit] = useState<VehicleEvent>();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEndDialog, setOpenEndDialog] = useState(false);

  const createMutation = useMutation({
    mutationFn: (event: VehicleEventCreate) => addVehicleEvent(vehicle.id, event),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, vehicle.id]);
      toast.success('Poprawnie dodano powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas dodawania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const deleteMutation = useMutation({
    mutationFn: (eventId: string) => deleteVehicleEvent(vehicle.id, eventId),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, vehicle.id]);
      toast.success('Poprawnie usunięto powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas usuwania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const updateMutation = useMutation({
    mutationFn: (event: Partial<VehicleEvent>) => updateVehicleEvent(vehicle.id, event),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, vehicle.id]);
      toast.success('Poprawnie edytowano powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas edycji powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const endEventMutation = useMutation({
    mutationFn: (event: Partial<VehicleEvent>) => updateVehicleEvent(vehicle.id, event),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicleNotifications', user?.id, vehicle.id]);
      toast.success('Poprawnie zakończono powiadomienie.');
    },
    onError: () => { toast.error('Błąd podczas zakańczania powiadomienia.'); },
    meta: { hideDefaultToast: true }
  });

  const handleDeleteEvent = (eventId: string) => deleteMutation.mutate(eventId);

  const handleOpenEditModal = (event: VehicleEvent) => { setOpenEditModal(true); setEventToEdit(event); };

  const formSubmitAddEvent = (event: VehicleEventCreate) => { createMutation.mutate(event); setOpenAddModal(false); };

  const formSubmitEditEvent = (event: Partial<VehicleEvent>) => { updateMutation.mutate(event); setOpenEditModal(false); };

  const handleClickOpenDialog = (eventName: string) => { setOpenDeleteDialog(true); setEventIdToAction(eventName); };
  const handleCloseDialog = () => { handleDeleteEvent(eventIdToAction); setOpenDeleteDialog(false); };

  const handleClickOpenEndDialog = (event: VehicleEvent) => {
    setEventToEdit(event);
    setOpenEndDialog(true);
  };

  const handleCloseAndCreateConfirmation = () => {
    user && navigate(RoutesVars.COMPANY_EVENTS_REFRESH(user.companyId, eventToEdit?.id as string));
    if (eventToEdit) {
      const event = {
        name: eventToEdit.name,
        start: eventToEdit.start,
        finish: eventToEdit.finish,
        status: EventsStatusKeys.FINISHED,
        endMileage: eventToEdit.endMileage,
        id: eventToEdit.id
      };
      endEventMutation.mutate(event);
    }
  };

  const handleCloseEndDialog = () => {
    if (eventToEdit) {
      const event = {
        name: eventToEdit.name,
        start: eventToEdit.start,
        finish: eventToEdit.finish,
        status: EventsStatusKeys.FINISHED,
        endMileage: eventToEdit.endMileage,
        id: eventToEdit.id
      };
      endEventMutation.mutate(event);
    }
    setOpenEndDialog(false);
  };

  const renderDescriptionIcon = (description: string) => {
    return (
      <Tooltip title={description || "Nie dodano opisu."}>
        <IconButton color='warning'>
          <PostAddIcon />
        </IconButton>
      </Tooltip>

    );
  };

  const renderDeleteButton = (props: GridRenderCellParams<GridValidRowModel, string>) => {
    return (
      <Tooltip title="Usuń powiadomienie">
        <Button variant="contained" size="small" sx={{ p: 0, fontSize: 12 }} onClick={() => handleClickOpenDialog(props as unknown as string)}>
          Usuń
        </Button>
      </Tooltip>

    );
  };
  const renderEditButton = (props: GridRenderCellParams<GridValidRowModel, VehicleEvent>) => {
    return (
      <Tooltip title="Edytuj powiadomienie">
        <Button variant="contained" size="small" sx={{ p: 0, fontSize: 12 }}
          onClick={() => handleOpenEditModal(props as unknown as VehicleEvent)}>
          Edytuj
        </Button>
      </Tooltip>

    );
  };
  const renderEndButton = (props: GridRenderCellParams<GridValidRowModel, string>) => {
    return (
      <Tooltip title="Zakończ powiadomienie">
        <IconButton color='success' onClick={() => handleClickOpenEndDialog(props as unknown as VehicleEvent)}>
          <CheckCircleIcon />
        </IconButton>
      </Tooltip>

    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        {events && events.length < 100 && (<GridToolbarExport />)}
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Nr.', flex: 20 },
    { field: 'eventKey', headerName: 'ID Powiadomienia', flex: 20 },
    { field: 'vehicleId', headerName: 'ID Pojazdu', flex: 20 },
    { field: 'typeMessage', headerName: 'Typ Powiadomienia', flex: 10 },
    { field: 'name', headerName: 'Powiadomienie', flex: 20 },
    { field: 'finish', headerName: 'Termin wykonania', headerAlign: 'center', align: 'center', flex: 15 },
    { field: 'start', headerName: 'Data dodania', headerAlign: 'center', align: 'center', flex: 15 },
    {
      field: 'endMileage', headerName: 'Limit (km)', headerAlign: 'center', align: 'center', flex: 15,
      renderCell: (params: GridRenderCellParams<GridValidRowModel, string>) => <div>{params.row.endMileage || '-'}</div>,
    },
    {
      field: 'status', headerName: 'Status', headerAlign: 'center', align: 'center', flex: 18,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => EventStatusTag(params.row)
    },
    {
      field: 'type', headerName: 'Termin', headerAlign: 'center', align: 'center', flex: 18,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => EventTypeTag(params.row)
    },
    {
      field: 'descriptionIcon', headerName: 'Dod. Informacje', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => renderDescriptionIcon(params.row.description),
    },
    {
      field: 'endButton', headerName: 'Zakończ Powiadomienie', headerAlign: 'center', align: 'center', flex: 3,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string>) => renderEndButton(params.row),
    },
    {
      field: 'editButton', headerName: 'Edytuj', headerAlign: 'center', align: 'center', flex: 10,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, VehicleEvent>) => renderEditButton(params.row),
    },
    {
      field: 'deleteButton', headerName: 'Usuń', headerAlign: 'center', align: 'center', flex: 10,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, string>) => renderDeleteButton(params.row.id),
    },
  ];

  const tableRows = React.useMemo(() => events.map((event) => {
    const { id, name, start, finish, status, endMileage, type, vehicleId, typeMessage, description } = event;
    return createRowData(id, vehicleId, typeMessage, name, start, finish, status, endMileage, type, description, event, name);
  }), [events]);

  return (
    <div style={{ width: '100%', position: 'relative', overflow: 'scroll' }}>
      <DataGrid
        sx={{ height: 300, minWidth: 1200 }}
        rowSelection={false}
        rows={tableRows}
        columns={columns}
        loading={isLoading}
        checkboxSelection
        slotProps={{
          toolbar: { printOptions: { getRowsToExport: getSelectedRowsToExport } },
        }}
        initialState={{
          pagination: { paginationModel: { page: 0, pageSize: 10 } },
          columns: {
            columnVisibilityModel: {
              id: false,
              eventKey: false,
              vehicleId: false,
              typeMessage: false,
              start: false,
              descriptionIcon: false,
            },
          },
        }}
        slots={{
          noRowsOverlay: () => TableEmptyState({
            text: 'Brak powiadomień do wyświetlenia...',
            buttonText: 'Dodaj nowe',
            handleClick: () => setOpenAddModal(true),
          }),
          toolbar: CustomToolbar
        }}
        pageSizeOptions={[5, 10, 20, 30, 50, 100]}
        localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
      />
      {!!events.length && (
        <Button variant="outlined"
          sx={{ position: 'absolute', bottom: '10px', left: '35px', mt: 1, fontWeight: 'bold', fontSize: 12 }}
          onClick={() => setOpenAddModal(true)}>
          Dodaj nowe powiadomienie
        </Button>
      )}
      {!isLoading && (
        <Modal
          aria-labelledby="add-notification-modal"
          open={openAddModal}
          onClose={() => setOpenAddModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={openAddModal}>
            <Box sx={modalStyle}>
              <AddVehicleEventForm {...{ vehicle, formSubmit: formSubmitAddEvent, submitText: 'Dodaj' }} />
            </Box>
          </Fade>
        </Modal>
      )}
      {eventToEdit && openEditModal && (
        <Modal
          aria-labelledby="add-notification-modal"
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={openEditModal}>
            <Box sx={modalStyle}>
              <EditVehicleEventForm {...{ vehicle, event: eventToEdit, formSubmit: formSubmitEditEvent, submitText: 'Edytuj' }} />
            </Box>
          </Fade>
        </Modal>
      )}
      <AlertDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleCloseConfirmation={handleCloseDialog}
        title='Usuń system powiadomień'
        content='Jesteś pewny ze chcesz usunąć wszyskie powiadomienia? Zmian nie można cofnąć!'
        approvalActionText='Usuń'
        cancelActionText='Odrzuć'
      />
      <AlertDialog
        open={openEndDialog}
        handleClose={() => setOpenEndDialog(false)}
        handleCloseConfirmation={handleCloseEndDialog}
        title='Zakończ powiadomienie'
        content='Jesteś pewny ze chcesz zakończyć to powiadomienie? Zmiany nie można cofnąć!'
        approvalActionText='Zakończ'
        cancelActionText='Odrzuć'
        handleCloseAndCreateConfirmation={handleCloseAndCreateConfirmation}
        eventToEnd={eventToEdit?.id}
      />
    </div>
  );
};

export default VehicleEventTable;