import React from 'react';
import { Button, TextField, Typography, FormControlLabel, Checkbox, Switch, Autocomplete, Stack } from '@mui/material';
import { useFormik } from 'formik';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { VehicleEvent } from '../../../interfaces';
import { EventsNameDefaultsOptions, EventsStatusKeys } from '../../../const/constNotifications';

interface Props {
  formSubmit: (event: Partial<VehicleEvent>) => void;
  submitText: string;
  event: VehicleEvent;
}

const EditVehicleEventForm: React.FC<Props> = ({ event, formSubmit, submitText }) => {
  const [includeEndMilage, setIncludeEndMilage] = React.useState(event.endMileage === 0 || !!event.endMileage);
  const [includeEndDate, setIncludeEndDate] = React.useState(event.finish !== null);
  const handleChangeEndMilage = (event: React.ChangeEvent<HTMLInputElement>) => { setIncludeEndMilage(event.target.checked); };
  const handleChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => { setIncludeEndDate(event.target.checked); };

  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(event.start));
  const [finishDate, setFinishDate] = React.useState<Dayjs | null>(dayjs(event.finish) || dayjs(new Date()));
  const [nameOptions, setNameOptions] = React.useState('');

  const formik = useFormik({
    initialValues: {
      name: event.name,
      start: startDate,
      finish: finishDate,
      endMileage: event.endMileage ? event.endMileage : 0,
      status: event.status === EventsStatusKeys.ACTIVE,
      id: event.id,
      description: event.description,
    },
    onSubmit: (values) => {
      const event: Partial<VehicleEvent> = {
        name: nameOptions ? nameOptions : values.name,
        start: startDate?.format('YYYY-MM-DD') as unknown as Date,
        finish: (includeEndDate) ? finishDate?.format('YYYY-MM-DD') as unknown as Date : null,
        endMileage: (includeEndMilage) ? values.endMileage : null,
        status: values.status ? EventsStatusKeys.ACTIVE : EventsStatusKeys.PAUSED,
        id: values.id,
        description: values.description ? values.description : '',
      };
      if (!includeEndDate && !includeEndMilage) return alert('Musisz wybrać minimum 1 opcje: km lub data');
      formSubmit(event);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography sx={{ mb: 4, textAlign: 'center', fontSize: 26, fontWeight: 'bold' }}>
        Dodaj nowe powiadomienie
      </Typography>

      <Autocomplete
        freeSolo
        id="name"
        disableClearable
        value={formik.values.name}
        onChange={formik.handleChange}
        inputValue={nameOptions}
        onInputChange={(event: React.SyntheticEvent<Element, Event>, newValue: string) => {
          setNameOptions(newValue);
        }}
        options={EventsNameDefaultsOptions.map((option) => option.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Nazwa powiadomienia"
            required
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            sx={{ marginBottom: '12px' }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
          />
        )}
      />
      <Stack flexDirection={{ xs: "column", md: "row" }} alignItems={{ md: "center" }} sx={{ mb: 2 }}>
        <DatePicker
          disabled
          key='start'
          label="Data dodania"
          value={formik.values.start}
          onChange={(newDate) => setStartDate(newDate)}
          slotProps={{
            textField: {
              variant: "outlined",
              error: formik.touched.start && Boolean(formik.errors.start),
              helperText: formik.touched.start && formik.errors.start,
            }
          }}
          sx={{ mr: { md: 2 } }}
        />

        <Stack flexDirection="row" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox checked={includeEndDate} onChange={handleChangeEndDate} />}
            label=""
            sx={{ mr: '-8px', ml: { md: '2px' } }}
          />

          <DatePicker
            disabled={!includeEndDate}
            key="finish"
            label="Termin wykonania"
            value={formik.values.finish}
            onChange={(newDate) => setFinishDate(newDate)}
            slotProps={{
              textField: {
                required: true,
                variant: "outlined",
                error: formik.touched.finish && Boolean(formik.errors.finish),
                helperText: formik.touched.finish && formik.errors.finish,
              }
            }}
            sx={{ mr: { md: 2 }, flexGrow: 1 }}
          />
        </Stack>

        <FormControlLabel
          control={<Checkbox checked={includeEndMilage} onChange={handleChangeEndMilage} />}
          label="Ustaw limit kilometrów"
          sx={{ mr: 3 }}
        />

        <FormControlLabel
          control={
            <Switch
              id="status"
              name="status"
              color="success"
              checked={formik.values.status}
              onChange={formik.handleChange}
            />}
          defaultChecked
          color="success"
          label="Aktywuj powiadomienia"
        />
      </Stack>

      <TextField
        fullWidth
        id="description"
        name="description"
        label="Dodatkowe informacje"
        inputProps={{ min: 0 }}
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
        sx={{ marginBottom: '12px' }}
      />

      {
        includeEndMilage && (
          <>
            <TextField
              fullWidth
              required
              type='number'
              id="endMileage"
              name="endMileage"
              label="Ustaw limit kilometrów"
              inputProps={{ min: 0 }}
              value={formik.values.endMileage}
              onChange={formik.handleChange}
              error={formik.touched.endMileage && Boolean(formik.errors.endMileage)}
              helperText={formik.touched.endMileage && formik.errors.endMileage}
              sx={{ marginBottom: '12px' }}
            />
          </>
        )
      }
      <Button
        color="primary"
        variant="contained"
        type="submit"
        fullWidth
      >
        {submitText}
      </Button>
    </form >
  );
};

export default EditVehicleEventForm;
