import React, { ReactElement, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { TextField, TextFieldProps } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';

export type SectionProps = {
  title: string;
  children?: React.ReactElement | string;
};

type Props<Type> = TextFieldProps & {
  list: Type[];
  keys: string[];
  callback: (arg: Type[]) => void;
  isDisabled?: boolean;
};

export default function SearchInput<Type>({
  list,
  keys,
  color = "primary",
  variant = "outlined",
  id = "search",
  label = "Szukaj",
  name = "search",
  fullWidth = true,
  isDisabled = false,
  callback,
}: Props<Type>): ReactElement {
  const [fuse, setFuse] = useState(new Fuse<Type>([]));
  const [pattern, setPattern] = useState('');

  useEffect(
    () => {
      const options: Fuse.IFuseOptions<Type> = { keys };
      setFuse(new Fuse(list, options));
    },
    [list, keys],
  );

  useEffect(
    () => {
      if (pattern.length === 0) {
        callback(list);
      } else {
        callback(fuse.search(pattern).map((item) => item.item));
      }
    },
    [pattern, callback, fuse, list],
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPattern(() => event.target.value);
  };

  return (
    <TextField
      disabled={isDisabled}
      color={color}
      variant={variant}
      margin="normal"
      id={id}
      label={label}
      name={name}
      fullWidth={fullWidth}
      onChange={onChange}
      type="search"

    // Lupa search na koncu baru
    // InputProps={{
    //   endAdornment: (
    //     <InputAdornment position="start">
    //       <SearchIcon />
    //     </InputAdornment>
    //   ),
    // }}
    />
  );
}
