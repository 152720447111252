import * as React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { RoutesVars } from '../../../../const/constRoutes';
import CopyrightComponent from '../CopyrightComponent';

export const PHONE_NUMBER = '+48 889 224 297';
export const EMAIL = 'kontakt.kierujflota@gmail.com';

const FooterLink = styled(Link)(() => ({
  fontSize: '20px',
  padding: 1,
  color: 'whitesmoke',
  textDecoration: 'none',
}));

const MobileContent: React.FunctionComponent = () => (
  <Stack direction="column">

    <Stack sx={{ mb: '12px' }}>
      <Typography aria-label="Numer Telefonu" sx={{ fontSize: '20px', fontWeight: 'bold' }}      >
        Telefon
      </Typography>
      <FooterLink href={`tel:${PHONE_NUMBER}`} aria-label="Kliknij aby zadzwonić do nas"      >
        +48&nbsp;889&nbsp;224&nbsp;297
      </FooterLink>
    </Stack>

    <Stack sx={{ mb: '12px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }} aria-label="Adres e-mail"      >
        Adres e-mail
      </Typography>
      <FooterLink href={`mailto:${EMAIL}`} aria-label="Kliknij aby wysłać do nas email"      >
        {EMAIL}
      </FooterLink>
    </Stack>

    <Stack sx={{ mb: '12px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 'bold', }} aria-label="Kontakt"    >
        Kontakt
      </Typography>
      <Link to={RoutesVars.CONTACT} component={RouterLink} underline="none" color="secondary">
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: 'whitesmoke', fontStyle: 'italic' }}>
          Formularz kontaktowy
        </Typography>
      </Link>
    </Stack>

    <Stack sx={{ mt: '20px' }}>
      <CopyrightComponent />
    </Stack>
  </Stack>
);

export default MobileContent;

