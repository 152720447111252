import * as React from 'react';
import { Box, Typography, useMediaQuery, ImageListItem, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getImageAsBase64 } from '../../../api/services/ImagesService';
import theme from '../../../themes/theme';

export default function ImageListSection() {
  const isDesktopView = useMediaQuery(theme.breakpoints.up('md'));
  console.log(isDesktopView);
  const { data: base64Images, isLoading, isError } = useQuery({ queryKey: ['images'], queryFn: () => getImageAsBase64() });
  const images = base64Images || [];

  if (isError) return null;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center" justifyContent="center"
      sx={{ px: 10, pb: 5 }}
      maxWidth={1200}
    >
      <Typography sx={{ fontSize: { xs: 30, sm: 35, md: 45, lg: 45 }, fontWeight: 'bold' }}>
        Zobacz Aplikację
      </Typography>

      {isLoading && <CircularProgress sx={{ mt: 5 }} />}

      {images && images.map((image) => (
        <ImageListItem key={image.name} sx={{ mt: { xs: 2, md: 4, } }}>
          <img
            srcSet={`${image.base64Image}`}
            src={`${image.base64Image}`}
            alt={image.name}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </Box >

  );
}

