import { axiosApi } from "../axios";
import { routes } from "../routes";

export interface base64ImageInterface {
  base64Image: string;
  name: string;
}

const getImageAsBase64 = async () => {
  const response = await axiosApi.get<base64ImageInterface[]>(routes.IMAGES_PICTURES);
  return response.data;
};

export {
  getImageAsBase64,
};
