import React, { useState, useEffect, FC, useMemo, useCallback, useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, Button, Fade, Backdrop, Modal, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';

import { addVehicle, getVehicles } from '../../api/services/VehicleService';
import { Vehicle, VehicleCreate } from '../../interfaces';
import AllVehiclesTable from './components/AllVehiclesTable';
import SearchInput from '../../components/SearchInput';
import AddVehicleForm from './components/AddVehicleForm';
import { UserContext } from '../../contexts/UserContext/UserContext';

export const modalStyle = {
  position: 'absolute' as const, top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
  width: '80%', bgcolor: 'background.paper', maxWidth: 1000, display: 'flex', flexDirection: 'column',
  border: '2px solid #000000ca', boxShadow: 24, px: 5, pb: 8, pt: 3, minHeight: 380,
};

const VehiclesListPage: FC = () => {
  const { state: { user } } = useContext(UserContext);
  const queryClient = useQueryClient();

  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [searchResult, setSearchResult] = useState<Vehicle[]>([]);

  const [openAddModal, setOpenAddModal] = useState(false);

  const { isLoading, isFetched, data: vehiclesData, isRefetching } = useQuery({
    queryKey: ['getVehicles', user?.id],
    queryFn: getVehicles,
    onSuccess: (data: Vehicle[]) => { setVehicles(data); },
    meta: { errorMessage: 'Błąd podczas ładowania pojazdów.' },
    retry: false,
  });

  const createMutation = useMutation({
    mutationFn: (newVehicle: VehicleCreate) => addVehicle(newVehicle),
    onSuccess: () => {
      queryClient.invalidateQueries(['getVehicles', user?.id]);
      toast.success('Poprawnie dodano pojazd.');
    },
    onError: () => { toast.error('Błąd podczas dodawania pojazdu.'); },
    meta: { hideDefaultToast: true }
  });

  useEffect(() => {
    isFetched && vehiclesData && setVehicles(vehiclesData);
  }, [isFetched, vehiclesData]);

  const searchKeys = useMemo(() => ['licensePlate', 'model'], []);
  const searchCallback = useCallback(
    (results: Vehicle[]) => {
      setSearchResult(results);
    }, [],
  );

  const handleAddVehicle = (newVehicle: VehicleCreate) => { createMutation.mutate(newVehicle); setOpenAddModal(false); };

  return (
    <div>
      <Box sx={{ px: 2, maxWidth: 1488, m: '0 auto' }}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <SearchInput
            list={vehicles} keys={searchKeys} isDisabled={isRefetching}
            callback={searchCallback} label="Wyszukaj w dostępnych pojazdach..."
          />
          <Button variant="contained" size="small" sx={{ p: 1, fontSize: 12, width: 180, mx: 2, fontWeight: 'bold' }}
            onClick={() => setOpenAddModal(true)}>
            Dodaj pojazd
          </Button>
        </Box>

        <AllVehiclesTable vehicles={searchResult} isLoading={isLoading || isRefetching} />

        <Modal
          aria-labelledby="add-notification-modal"
          open={openAddModal}
          onClose={() => setOpenAddModal(false)} //TODO: handle empty list
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{ backdrop: { timeout: 500 } }}
        >
          <Fade in={openAddModal}>
            <Box sx={modalStyle}>
              <Typography
                sx={{ mb: 3, textAlign: 'center', fontSize: 32, fontStyle: 'italic', fontWeight: 'bold', textTransform: 'uppercase' }}>
                Dodaj Pojazd
              </Typography>
              <AddVehicleForm {...{ formSubmit: handleAddVehicle, submitText: 'Zatwierdź' }} />
            </Box>
          </Fade>
        </Modal>

      </Box>
    </div>
  );
};

export default VehiclesListPage;