import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { RoutesVars } from '../../../../const/constRoutes';
import CopyrightComponent from '../CopyrightComponent';

export const PHONE_NUMBER = '+48 889 224 297';
export const EMAIL = 'kontakt.kierujflota@gmail.com';

const FooterLink = styled(Link)(() => ({
  fontSize: '20px',
  padding: 1,
  color: 'whitesmoke',
  textDecoration: 'none',
}));

const DesktopContent: React.FunctionComponent = () => (
  <Stack sx={{ maxWidth: 1600, m: 'auto', width: '100%', p: '10px 50px' }}>
    <Stack direction="column" flexGrow="1">
      <Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack direction="column" sx={{ m: '10px' }}>
            <Stack sx={{ mb: '12px' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }} aria-label="Numer Telefonu">
                Telefon
              </Typography>
              <FooterLink href={`tel:${PHONE_NUMBER}`} sx={{ color: 'whitesmoke' }} aria-label="Kliknij aby zadzwonić do nas">
                +48&nbsp;889&nbsp;224&nbsp;297
              </FooterLink>
            </Stack>
          </Stack>
          <Stack direction="column" sx={{ m: '10px' }}>

            <Stack sx={{ mb: '12px' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: 'bold' }} aria-label="Adres e-mail">
                Adres e-mail
              </Typography>
              <FooterLink href={`mailto:${EMAIL}`} aria-label="Kliknij aby wysłać do nas email">
                {EMAIL}
              </FooterLink>
            </Stack>
          </Stack>

          <Stack direction="column" sx={{ m: '10px' }}>
            <Typography sx={{ fontSize: '22px', fontWeight: 'bold', mb: '1px' }} aria-label="Kontakt">
              Kontakt
            </Typography>

            <Stack sx={{ mb: '12px' }}>
              <Link to={RoutesVars.CONTACT} component={RouterLink} >
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: 'whitesmoke', fontStyle: 'italic' }}>
                  Formularz kontaktowy
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>

        <Stack sx={{ mt: '10px' }}>
          <CopyrightComponent />
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);

export default DesktopContent;
