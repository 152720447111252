import React, { FC } from 'react';
import { Box, styled } from '@mui/material';
import { PageViews } from '@piwikpro/react-piwik-pro';

import AppBarGuest from '../../../components/shared/AppBar/AppBarGuest';
import Footer from '../../../components/shared/Footer';
import ImageListSection from './ImageList';

const LayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const GaleryPage: FC = () => {
  PageViews.trackPageView('View Galery Page');

  return (
    <LayoutContainer>
      <AppBarGuest />
      <Box sx={{ flexGrow: '1', m: '0px auto', pt: 15, }}>
        <ImageListSection />
      </Box>

      <Footer />
    </LayoutContainer >
  );
};

export default GaleryPage;
