import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import truckBackground from '../../../../assets/images/truckBackground3.jpg';
import { Link, useLocation } from 'react-router-dom';

const IntroSection: FC = () => {
  const location = useLocation();
  return (
    <Box
      flexGrow='1'
      display='flex'
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundImage: `url(${truckBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: 'center center',
        height: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          px: { xs: 1, sm: 2, md: 8, lg: 8 },
          pt: { xs: 15, sm: 18, lg: 20 },
          textAlign: 'center',
          color: '#FFFFFF',
          maxWidth: 1600
        }}>
        <Typography sx={{ fontSize: { xs: 24, sm: 40, md: 50, lg: 60 }, fontWeight: 400 }}>
          Zapomnij o ręcznym śledzeniu przeglądów i terminów
          <Typography sx={{ fontSize: { xs: 24, sm: 28, md: 35, lg: 48 }, fontWeight: 400 }}>
            – nasza aplikacja robi to za Ciebie, sprawiając, że zarządzanie
            flotą staje się przyjemnością!
          </Typography>
        </Typography>
      </Box>
      {location.hash !== '#about' && (
        <Link to="#about">
          <Button color="primary" variant="contained"
            sx={{ mb: 4, width: 60, height: 24 }}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </Link>
      )}

    </Box >
  );
};

export default IntroSection;

